<template>
    <v-card class="pa-3">
        <v-row class="detail-box">
            <v-col cols="12"
                   lg="6"
                   class="pa-1">
                <div v-if="card.image">
                    <v-img v-if="card.isActive"
                           contain
                           class="auth-3d-group overflow-hidden"
                           :src="card.image">
                    </v-img>
                    <div v-if="!card.isActive">
                        <v-img contain
                               class="auth-3d-group overflow-hidden"
                               :src="card.image"
                               style="position: relative; ">
                            <div style="border-radius: 5px; position: absolute;background-color: rgba(255, 255, 255, 0.70);width: 100%;height: 100%;margin: 0px;"
                                 class="d-flex align-center">
                                <v-img contain
                                       height="50px"
                                       class="auth-3d-group overflow-hidden"
                                       :src="require('@/assets/images/icons/lock.png')">
                                </v-img>
                            </div>
                        </v-img>

                    </div>
                </div>

            </v-col>

            <v-col lg="6"
                   class="pa-1">
                <div class="mt-1"
                     v-if="card.isActive"
                     style="background: rgba(9, 180, 169, 0.15); color: #09B4A9; width: 50px; font-size: 12px;">
                    <div class="d-flex justify-center">
                        {{ $t('Active') }}
                    </div>
                </div>
                <div class="suspended"
                     v-if="!card.isActive">
                    <div class="d-flex justify-center">
                        {{ $t('Temporary suspended') }}
                    </div>

                </div>
                <div style="line-height: 17px;"
                     class="text-black font-weight-bold mt-1">
                    <span style="font-size: 12px;"
                          v-if="selectedLanguage == 'ar'">{{ card.cardNameAr }}</span>
                    <span style="font-size: 12px;"
                          v-if="selectedLanguage == 'en'">{{ card.cardName }}</span>
                </div>
                <div class="mt-2"
                     style="line-height: 13px;">
                    <span style="font-size: 10px;">
                        {{ card.cardMemberName }}
                    </span>
                </div>
                <div>
                    <span style="font-size: 12px;">
                        {{ $t('Card ending -') }} {{ card.Last4Digits }}
                    </span>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="!singleCard">
            <v-divider class=""></v-divider>
        </v-row>
        <v-row v-if="!singleCard"
               class="text-blue font-weight-bold justify-center">
            <span class="manage-btn"
                  style="cursor: pointer; font-size: 13px;"
                  @click="manageCard()">
                {{ $t('Manage Card') }}
            </span>
        </v-row>
    </v-card>
</template>

<script>

import store from '@/store';

export default {
    props: {
        cards: {
            default()
            {
                return []
            },
            type: Array
        },
        card: {
            default()
            {
                return {}
            },
            type: Object
        },
        singleCard: {
            default: false,
            type: Boolean
        },
        isSupplementarySelected: {
            default: false,
            type: Boolean
        },
    },
    data: () => ({
        selectedLanguage: 'en'
    }),

    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        }
    },
    methods: {
        manageCard()
        {
            this.$emit('manage', {
                cards: this.cards,
                isSupplementarySelected: false,
                card: this.card,
                singleCard: this.singleCard
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';


.manage-btn {
    padding: 9px 0px 10px;
}

.v-card {
    box-shadow: none !important;
}

.suspended {
    background: rgb(255 170 174 / 16%);
    color: #D92D20;
    width: 126px;
    font-size: 10px;
    padding: 2px 0px 2px;
    margin: 3px 0px -1px;
}
</style>