<template>
    <div class="view-transaction auth-wrapper auth-v1">
        <div class="auth-inner main-container-width">

            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">
                    <v-card flat
                            class="bg-gradient">
                        <app-header></app-header>
                        <div v-if="showAlert"
                             class="mt-3">
                            <alert :message="alertMessage"
                                   :type="alertType"></alert>

                        </div>
                        <div class="mt-3">
                            <BackAction text="Back"
                                        :output="false" />
                        </div>
                        <v-row class="px-8 pt-5">
                            <v-col cols="12"
                                   lg="4"
                                   class="mt-2">

                                <v-card class="pa-4 transaction">
                                    <PrimaryCard :card="cardDetails"
                                                 :isSupplementarySelected="false"
                                                 :singleCard="true"></PrimaryCard>
                                </v-card>
                            </v-col>
                            <v-col cols="12"
                                   lg="8">
                                <CardDetailTrans :selectedLanguage="selectedLanguage"
                                                 :card="cardDetails"></CardDetailTrans>
                            </v-col>
                        </v-row>
                        <v-card class="security pa-4 mx-lg-8 mx-md-2 mx-sm-1 mx-1 mt-3">
                            <Transaction :transactionsArray="transactionsArray"
                                         :showBtn="showBtn"></Transaction>
                        </v-card>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import AppHeader from '../test/AppHeader.vue'
import CardDetailTrans from './CardDetailTrans.vue';
import store from '@/store';
import moment from 'moment'
import alert from '@/views/alert.vue';
import Transaction from '../transactions/Transaction.vue';
import PrimaryCard from '../card-details/PrimaryCard.vue';

import keyValueMaper from '@/@core/utils/keyValueMaper';

import BackAction from '@/@core/BackAction.vue';

export default {
    components: {
        AppHeader,
        PrimaryCard,
        CardDetailTrans,
        Transaction,
        alert,
        BackAction
    },

    data: () => ({
        cardDetails: store.get('card/details'),
        showBtn: false,

        alertMessage: '',
        alertType: '',
        showAlert: false,
        selectedLanguage: 'en',
        pendingTransactionsArray: [],
        transactionsArray: [],
    }),
    created() 
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    mounted()
    {
        store.dispatch('loader/show');
        let lang = localStorage.getItem('language');
        if (lang == 'ar')
        {
            this.selectedLanguage = lang;
        }
        else
        {
            this.selectedLanguage = lang;
        }

        let pendingTransaction = store.get('card/pendingTransaction');

        if (pendingTransaction?.data?.authorizationsLength)
        {
            this.pendingTransactionsArray = pendingTransaction.data.authorizations;
            this.formatPendingTransactions();
        }
    },
    methods: {
        formatPendingTransactions()
        {
            let list = [];

            this.pendingTransactionsArray.forEach(transaction =>
            {
                var date = transaction.localeDate;

                var amount = keyValueMaper.divideByExponents(transaction.transactionAmount.amt, transaction.transactionAmount.curCode);

                var currCode = keyValueMaper.getCurrency(transaction.transactionAmount.curCode || '');

                var dateTime = `${date}`;

                var cr = null;

                var city = transaction.keyValues?.find((element) => element.key == "MerchantCity").data;

                var postedDate = moment(transaction.transmissionTime).utc().format('DD MMM, yyyy');;

                var merchant = transaction.keyValues?.find((element) => element.key == "MerchantName").data;


                let finalTransaction = {
                    dateTime: moment(dateTime).utc().format('DD MMM, yyyy'),
                    date: `${moment(dateTime).day()}-${moment(dateTime).month()}-${moment(dateTime).year()}`,
                    dateSort: moment(dateTime).utc().format('dddd DD, MMMM'),

                    amount: amount,
                    currencyCode: currCode,
                    icon: require(`@/assets/images/icons/${cr != null ? 'transaction-success' : 'merchandise'}.png`),
                    billedAmount: amount,
                    postingDate: postedDate,
                    merchant: merchant,
                    title: merchant,
                    city: city,

                    dialogDetail: {
                        'Posting Date': moment(dateTime).utc().format('DD MMM, yyyy'),
                        'Billing Amount': {
                            amt: amount,
                            cur: currCode
                        },
                        // 'Currency': currCode,
                        'Transaction Date': postedDate,
                        Merchant: merchant,
                        City: city,
                    }
                };

                list.push(finalTransaction);
            });

            list.reverse();
            this.transactionsArray = list;
            store.dispatch('loader/hide');
        }
    },

}
</script>

<style lang="scss" scoped>
.white {
    background: white;
}

.v-card {
    box-shadow: none !important;
}

.grey {
    background-color: #D9D9D9;
}

.view-transaction {
    padding-bottom: 0;
    padding-top: 0
}

::v-deep .v-select__selection--comma {
    margin: 7px 4px 7px 0;
    min-height: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    direction: ltr;
}

::v-deep .v-list-item__content {
    align-items: center;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    overflow: hidden;
    padding: 12px 0;
    direction: ltr;
}

.card-show {
    padding: 16px 18px 16px 0px;
}

@import '@core/preset/preset/auth.scss';
</style>
